import React, { useEffect, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useSiteContext } from '../../../../contexts/SiteContext';
import { isEmpty } from 'lodash';
import MiniCartItem from './MiniCartItem';
import MiniCartItemSkeleton from './skeltons/MiniCartItemSkeleton';

type Props = {};

const ViewCartLoadingIndicator = () => {
  return (
    <div className="flex justify-center items-center gap-5 w-full flex-1 bg-brand-primary-light border border-transparent text-base font-semibold py-3 h-12 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-brand-gold sm:w-full uppercase">
      <div className="w-4 h-4 bg-white animate-ping rounded-full flex items-center justify-center">
        <div className="w-2 h-2 bg-white animate-ping rounded-full flex items-center justify-center"></div>
      </div>
      Loading Cart...
    </div>
  );
};

const ViewCartLink = ({ session_id }: { session_id: string }) => {
  if (isEmpty(session_id)) {
    return null;
  }

  return (
    <a
      href={`${process.env.NEXT_PUBLIC_WORDPRESS_SITE_URL}/checkout/?session_id=${session_id}`}
      className="btn-view-cart button-gold-solid w-full block py-3"
    >
      Proceed to Checkout
    </a>
  );
};

const MiniCart = (props: Props) => {
  const {
    miniCartState,
    cart,
    wooCustomerSessionId,
    fetchingCart,
    cartUpdating,
    setCartUpdating,
  } = useSiteContext();
  const [open, setOpen] = miniCartState;

  const hasCartItems =
    cart !== null && cart?.products?.length > 0 ? true : false;

  useEffect(() => {
    setTimeout(() => {
      const html = document.body.parentNode as HTMLElement;
      html.style.overflow = open ? 'hidden' : 'auto';
    }, 1000);
  }, [open]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-[344px]">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="flex-1 overflow-y-auto py-6 px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="font-bold text-black text-sm uppercase">
                          Shopping basket
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                            onClick={() => setOpen(false)}
                          >
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>

                      <div className="mt-8">
                        <div className="flow-root">
                          {hasCartItems ? (
                            <ul
                              role="list"
                              className="-my-6 divide-y divide-gray-200"
                            >
                              {cart.products.map((cartItem: any, i: number) => {
                                return (
                                  <li key={i} className="flex py-6">
                                    <MiniCartItem cartItem={cartItem} />
                                  </li>
                                );
                              })}
                            </ul>
                          ) : (
                            <>
                              {!fetchingCart && (
                                <p className="text-center">
                                  No Items in the cart
                                </p>
                              )}
                            </>
                          )}
                          {fetchingCart && (
                            <ul
                              role="list"
                              className="-my-6 divide-y divide-gray-200"
                            >
                              <li className="py-6">
                                <MiniCartItemSkeleton />
                              </li>
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>
                    {hasCartItems ? (
                      <div className=" py-6 pt-0 px-4 sm:px-6">
                        <div className="flex text-brand-primary justify-center gap-2 text-base font-bold border-y border-y-brand-second-gray py-6 ">
                          <p>Subtotal:</p>
                          <p>{cart.totalProductsPrice}</p>
                        </div>
                        <div className="mt-6 mb-12">
                          {cartUpdating || fetchingCart ? (
                            <ViewCartLoadingIndicator />
                          ) : (
                            <>
                              <button
                                onClick={() => setOpen(false)}
                                className="
                                    w-full bg-[#E7DFCD] 
                                    border border-brand-second-gray 
                                    gap-5 mb-3 py-2.5 px-5 
                                    text-[12px] hover:bg-[#3a3a3a] 
                                    hover:text-white 
                                    tracking-[0.15em] 
                                    uppercase
                                    text-center
								"
                              >
                                Continue Shopping
                              </button>
                              <ViewCartLink session_id={wooCustomerSessionId} />
                            </>
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default MiniCart;
