import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { numberFormat } from '../../../../utils/product/product';
import { useSiteContext } from '../../../../contexts/SiteContext';
import { CgClose } from 'react-icons/cg';
import Image from '../../../common/Image';
import { useMutation } from '@apollo/client';
import { REMOVE_CART_ITEM } from '../../../../utils/queries/cart';
import { getFormattedCart } from '../../../../utils/cart';
import MiniCartItemSkeleton from './skeltons/MiniCartItemSkeleton';
import getImgUrl from '../../../../utils/getImageUrl';

type Props = {
    cartItem: any;
};

const MiniCartItem = ({ cartItem }: Props) => {
    const [cartKey, setCartKey] = useState(cartItem.cartKey);

    const { setCart, setCartUpdating } = useSiteContext();

    /**
     * Adding product to cart
     * Original and should be used is ADD_TO_CART
     */
    const [
        removeItemFromCart,
        { data: actionResponse, loading: isRemoving, error: removingCartError },
    ] = useMutation(REMOVE_CART_ITEM, {
        variables: {
            cartKey: cartKey,
        },
        onCompleted: (data) => {
            // Update cart data in React Context.
            setCart(getFormattedCart(data.removeItemsFromCart));
            setCartUpdating(false);
        },
        onError: (error) => {
            if (error) {
                console.log(error?.graphQLErrors?.[0]?.message ?? '');
            }
        },
    });

    useEffect(() => {
        if (isRemoving) {
            setCartUpdating(true);
        }
    }, [isRemoving, setCartUpdating]);

    if (isRemoving) {
        return <MiniCartItemSkeleton />;
    }

    return (
        <>
            <div className="h-[61px] w-[68px] flex-shrink-0 overflow-hidden border border-gray-200">
                <Image
                    src={getImgUrl(cartItem.image.sourceUrl)}
                    alt={cartItem.image.altText}
                    width={68}
                    height={61}
                    className="h-full w-full object-cover object-center"
                />
            </div>

            <div className="ml-4 flex flex-1 flex-col">
                <div>
                    <div className=" flex flex-col ">
                        <h3 className="leading-3">
                            <a
                                href={`#`}
                                className=" text-sm font-medium text-brand-primary"
                            >
                                {cartItem.name}
                            </a>
                        </h3>
                        <span className="text-brand-primary ">
                            <span className="text-sm font-light">
                                {cartItem.qty}
                            </span>{' '}
                            x{' '}
                            <span className="font-semibold text-[#A29061] text-sm">
                                {numberFormat(cartItem.price)}
                            </span>
                        </span>
                    </div>
                </div>
                <div className="flex flex-1 items-end justify-between text-sm">
                    <p className="text-gray-500"></p>

                    <div className="">
                        <button
                            type="button"
                            className="btn-remove-item-from-cart group hover:border-brand-primary w-5 h-5 border border-brand-second-gray rounded-full text-indigo-600 hover:text-indigo-500 flex items-center justify-center"
                            onClick={() =>
                                removeItemFromCart({
                                    variables: { cartKey: cartItem.cartKey },
                                })
                            }
                        >
                            <CgClose
                                className=" text-brand-second-gray group-hover:text-brand-primary"
                                aria-hidden="true"
                            />
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MiniCartItem;
